<script setup>   
    import { defineProps, computed } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import ModalCreateLease from "lease.libraries.vue.components.CreateLease.vue";
    import IFrame from 'o365.vue.components.Iframe.vue';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    }); 
    

    const dsLATWorkflow = getOrCreateDataObject({
        id: 'dsLATWorkflow',
        viewName:  'aviw_Scope_Items',
        uniqueTable: 'atbv_Scope_Items',
        allowUpdate: true,
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        whereClause: props.itemId ? `ID = ${props.itemId}` : "1=0",
        distinctRows: false,
        fields:[
            {name: "ID", type: "number" },
            {name: "OrgUnit_ID", type: "number" },
            {name: "OrgUnit", type: "string" },
            {name: "Lease_ID", type: "number" },
        ]
    });

    const dsLease = getOrCreateDataObject({
        id: 'dsLease',
        viewName: 'aviw_Lease_LeasesScopeItems',
        maxRecords: 1,
        distinctRows: false,
        disableAutoLoad: false,
        selectFirstRowOnLoad: true,
        whereClause: "Closed IS NULL",
        masterDataObject_ID: `dsLATWorkflow`,
        masterDetailDefinition: [{"masterField": "ID", "detailField": "ScopeItem_ID", "operator": "equals"}],
        fields:[
            { name: "ID", type: "number" },
            { name: "LesseeOrgUnit_ID", type: "number" },
            { name: "ScopeItem_ID", type: "number" },
            { name: "Name", type: "string" }, 
            { name: "Title", type: "string" }
        ]
    });

    if(!props.readOnly) {
        dsLATWorkflow.load();
    }
    const vUrlTabs = computed(() => { return `/lease?hideNav=true&ID=${dsLease?.current.ID}&hideTabs=AvailableRooms,Additions,OrderItems,Documents,Workflows&activeTab=ContractElements` })
    const vUrl = computed(() => { return `/lease?ID=${dsLease?.current.ID}` })
</script>

<template>
    <div v-if="!readOnly && dsLease.state._isLoaded">
        <div v-if="dsLease.current.ID">
            <a :href="vUrl" target="_blank"><i class="bi bi-box-arrow-up-right"></i> {{ $t( "Open lease in new tab" ) }}</a>
            <IFrame :src="vUrlTabs" ref="vIframe" style="height:100%;width:100%;min-height:600px;"/> 
        </div>
    </div>
</template>